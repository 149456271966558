body {
  margin: 10px; 
  margin-top: 55px;
  background-color: lightgray;
}
a {
  text-decoration: none;
  color: #000;
}

a.hover {
  text-decoration: none;
  color: #000;
}

.mw-content-ltr {
  direction: ltr;
}

ul.gallery {
  margin: 0px;
  margin-top: 10px;
  padding: 0px;
  display: block;
}

li.gallerybox {
  vertical-align: top;
  display: inline-block;
  margin: 2px;
}

.pinbox {
  width: 100px;
}

div.thumb {
  width: 100px;
  margin: 0px auto;
  border: 1px solid #000;
  background-color: #f9f9f9;
}

.center {
  text-align: center;
}


.App {
  text-align: center;
}

.pinname {
  text-align: center;
  font-size: 80%;
  line-height: 120%;
}
.pinset {
  text-align: center;
  font-size: 60%;
  font-style: italic;
}

img {
  border: none;
  vertical-align: middle;
}


.search-box {
  position: fixed;
  top: 7px;
  right: 70px;
  width: 300px;
  z-index: 9998!important;
  display: flex;
  flex-flow: row-wrap;
  align-items: center;
}

@media screen and (max-width: 1170px) {
  .search-box {
    position: static;  
    right: 0px;
    left: 250px;
    top: 7px;
    width: 300px;
    padding: 15px;    
    margin-left: auto;
    margin-right: auto;
    text-align: left;    
  }
  ul.gallery {  
    margin-top: 0px;
    }  
}

.page-nav {
  margin: 15px 0 0 0 ;
  border-radius: 0;
  display: inline-block;
  padding-left: 0;
}

.page-nav li a {
    position: relative;
    float: left;
    display: block;
    width: 40px;
    height: 40px;
    font-size: 13px;
    line-height: 40px;
    padding: 0;
    text-align: center;
    background-color: #f5f5f5;
    color: #333333;
}

.detail-box {
  background-color: #fff;
  padding: 10px;
  margin-top: 65px;
}

.set-name {
  margin-top: 10px;
  font-size:medium;
}
.dropdown-item {
  font-size:smaller;
  line-height: 100%;
}

.tag-list {
  list-style-type: none;
  margin-top: 10px;
  padding: 0;
  overflow: hidden;
}
.tag-list-item {
  float: left;
  margin-right: 15px;
  border: 0px solid black;
  border-radius: 10px;
  padding: 4px 10px 4px 10px;
  background-color: #4d4d4d;
}

.tag-list-item a {
  color: white;
  font-size: smaller;
  font-weight: bold;
}



.footer-text {
  font-size: x-small;
}


.column {
  float: left;
  padding: 10px;
}

.left {
  width: 150px;
}

.right {
  width: 75%;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}


.mw-content-ltr {
  margin-top: 70px;
}

.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.pagination a.active {
  background-color: #4CAF50;
  color: white;
  border: 1px solid #4CAF50;
}

.pagination a:hover:not(.active) {background-color: #ddd;}

.image-gallery-row {
  vertical-align: top;
  display: inline-block;
  margin: 2px;
}

.image-gallery-column {
  width: 200px;
}

.sb-example-1 .search {
  width: 100%;
  position: relative;
  display: flex;
  position: fixed;
  top: 14px;
  right: 80px;
  width: 150px;
  z-index: 9998!important;
  flex-flow: row-wrap;
  align-items: center;
}
.sb-example-1 .searchTerm {
  width: 100%;
  height: 30px;
  border: 3px solid #f7de8c;
  border-right: none;
  padding: 5px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9DBFAF;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.sb-example-1 .searchTerm:focus{
  color: black;
}
.sb-example-1 .searchButton {
  width: 40px;
  height: 30px;
  border: 3px solid #f7de8c;
  border-left: none;
  background: white;
  text-align: left;
  cursor: pointer;
  font-size: 20px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;  
}

.sb-example-1 .searchButton i{
  color: black;
  vertical-align:top;
}

.ad-container {
  display: flex;
  justify-content: center;
}

.googlead {
  width: 728px;
}

@media all and (min-width: 800px) {
	.navbar .nav-item .dropdown-menu{ display: none; }
	.navbar .nav-item:hover .nav-link{   }
	.navbar .nav-item:hover .dropdown-menu{ display: block; }
	.navbar .nav-item .dropdown-menu{ margin-top:0; }

}

@media screen and (max-width:500px) {
  .googlead{
    width: 320px; 
  }
}